import "mmenu-light";

export default (el) => {

    const navButton = document.querySelector( '#js-mobile-nav-button' )
    const navMenu =  document.querySelector( "#js-mobile-nav" )
    
    const menu = new MmenuLight( navMenu )
 
    const navigator = menu.navigation({
        title : "Meny"
    })
    const drawer = menu.offcanvas({
        position : "right"
    });

    navButton.addEventListener( 'click', ( evnt ) => {
        evnt.preventDefault()
        drawer.open()
    })

   
    
}
