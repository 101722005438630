//const imagesLoaded = require('imagesloaded');

// class Helpers {


//     static domReady() {
//         return new Promise(resolve => {

//             document.addEventListener('DOMContentLoaded', (event) => {
            
//                 document.body.classList.add('is-ready')
//                 resolve()
                
//             })

//         })
//     }

// }


const domReady = () => {

    return new Promise(resolve => {

        document.addEventListener('DOMContentLoaded', (event) => {
        
            document.body.classList.add('is-ready')
            resolve()
            
        })

    })

}    


// Preload images
// const imagesReady = (selector = 'img') => {
//     return new Promise((resolve) => {
//         imagesLoaded(document.querySelectorAll(selector), resolve);
//     });
// };



export { 
    domReady,
    //imagesReady
}
